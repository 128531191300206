import React from "react";
import Routes from "./RoutPage/Routes"
import ErrorDialog from "./componant/openErrorDialog.tsx";

// import Login from "./Pages/Login";
// import Servises from "./Pages/Servises";
// import DigiBill from './Pages/DigiBill';
// import SmartPose from "./Pages/SmartPose";
// import SmartMsg from "./Pages/SmartMsg";
// import CocaCola from "./Pages/CocaCola";
// import InApp from "./Pages/InApp";
// import EBCM from "./Pages/EBCM";
// import SmartCnt from "./Pages/SmartCnt";
// import VCard from './Pages/VCard';
// import Cards from './Pages/Cards'
// import AddCard from "./Pages/AddCard";
// import UserAuthorizedRoutes from "./RoutPage/UserAuthorizedRoutes";



function App() {
  return (
    <div >
      
     
              <Routes/>
              <ErrorDialog/>
     
    </div>
  );
}

export default App;

            