import react from "react";
import "../index.css";

const Loade = () => {
  return (
    <div>
      <div className="main-loader" />
      <span className="loader" />
    </div>
  );
};

export default Loade;
